import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

function CardItem(props) {

        const [isMobile, setIsMobile] = useState(false);
      
        useEffect(() => {
          // Function to handle screen size changes
          const handleResize = () => {
            const isMobileWidth = window.matchMedia('(max-width: 830px)').matches;
            setIsMobile(isMobileWidth);
          };
      
          // Initial check for screen size
          handleResize();
      
          // Add event listener for screen size changes
          window.addEventListener('resize', handleResize);
      
          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);


      const [isModalOpen, setModalOpen] = useState(false);
     
      useEffect(() => {
       // Add or remove a class to the body based on the value of isModalOpen
       if (isModalOpen) {
       document.body.style.overflow = 'hidden';
       } else {
       document.body.style.overflow = 'auto';
       }

    // Cleanup effect
       return () => {
        document.body.style.overflow = 'auto';
       };
       }, [isModalOpen]);

      const openModal = () => {
        if (window.innerHeight < 550) {
          setModalOpen(false);
          return; // Exit the effect early if the condition is met
        }
        setModalOpen(true);
        };

      const closeModal = () => {
        setModalOpen(false);
        };

        const overlayStyle = {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.7)',
            zIndex: 999
          };
    
          const modalStyle = {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: isMobile ? 'translate(-50%, -50%) scale(1.0)': 'translate(-50%, -50%) scale(1.3)',
            backgroundColor: '#e8e7e5',
            padding: '20px',
            borderRadius:'5px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            zIndex: 1000,
            width:'80%',
            margin:'0 auto',
            minWidth: isMobile ?'0':'560px',
            maxWidth: isMobile? '100%':'0',
            justifyContent:'center'
          };

          const imgStyle={
            position: 'absolute',
            justifySelf: 'left',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            transition: 'all 0.2s linear'
          };

  return (
    <>
    <li className="cards__item" ></li>
        <div className="cards__item__link" onClick={openModal}>
            
            <figure className="cards__item__pic-wrap" data-category={props.label}>
                <img src={props.src} alt="Travel Image" className="cards__item__img" />
            </figure>
            <div className='cards__label'>{props.label}</div>
            <div className="cards__item__info">
                <h5 className="cards__item__text">
                    {props.text}
                </h5>
            </div>
        </div>

        {isModalOpen && (
        <div className="card_modal" style={modalStyle}>
          <div className="cards__item__link" >
            
            <figure className="cards__item__pic-wrap" data-category={props.label}>
                <img src={props.src} alt="Travel Image" className="cards__item__img" style={imgStyle}/>
            </figure>
            <div className='cards__label'>{props.label}</div>
            {/* <div className="cards__item__info">
                <h5 className="cards__item__text">
                    {props.text}
                </h5>
            </div> */}
          </div>    
        </div>)}

        {isModalOpen && (
        <div
          style={overlayStyle}
          onClick={closeModal}
        ></div>
      )}
    </>
  )
}

export default CardItem