import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection.js';
import Cards from '../Cards.js';
import AboutMe from '../AboutMe.js';
import Record from '../Record.js';
import Team from '../Team.js';


function Home() {
  return (
    <>
    <HeroSection />
    <AboutMe/>
    <Record/>
    <Cards/>
    <Team/>
    </>
  )
}

export default Home