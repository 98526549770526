import React from 'react'

import './Testimonials.css';

function Testimonials() {
  return (
    <div className='Testimonials__MainContainer'>
        <div className='Testimonials__header'>Testimonials</div>
        <div className='Testimonials__container'>
            <div className="testimonial_1">
                <blockquote>
                Yvonne has been exceptionally helpful and attentive. She is very responsible and will update us regularly on potential buyers' appointments and offers in the sale of our flat. It has been a very pleasant experience and we 
                highly recommend her services. She will have her clients' interests at heart and dispense advice accordingly.
                </blockquote>
                <div></div>
                <p>
                Esther Tan Li Ling
                </p>
            </div>

            <div className="testimonial_2">
                <blockquote>
                I would like to use this opportunity to thank Yvonne See for helping me seek my first property in Singapore. Yvonne is very active and responsible, she knows I am busy with my own job most of the time, 
                so she will collect information as much as possible and sort it out for me to check. And every time she will proactively share property news and her own opinion of the property with me. 
                <br></br>
                <br></br>
                I was successful in making a transaction with the seller and in the meantime I also made a very good friend.
                </blockquote>
                <div></div>
                <p>
                Zai Xing Wang
                </p>
            </div>
        </div>
    </div>
  )
}

export default Testimonials