import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse} from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const [click, setClick]= useState(false);
  const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu= () => setClick(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [navClick, setNavClick]=useState(false);


  const checkNavClick=()=>{
    setNavClick(true);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const showButton= () => {
    if(window.innerWidth <=1040){
      setButton(false);
    } else{
      setButton(true);
    }
  };

  useEffect(()=>{
    showButton();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Listen for the resize and scroll events
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

  window.addEventListener('resize',showButton)
  const scrollThreshold = 1.0;
  return (
    <>
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/" className={`navbar-logo ${windowWidth < 1040 && (scrollPosition>window.innerHeight * scrollThreshold|| navClick)?'scrolled':''}`}
        onClick={() => { closeMobileMenu(); scrollToTop(); setNavClick(false);}}>
          <p>Yvonne See Properties <FontAwesomeIcon icon={faHouse} /></p>
        </Link>
        </div>
        <div className="navbar-container">
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? `fas fa-times ${windowWidth < 1040 && (scrollPosition>window.innerHeight * scrollThreshold|| navClick)?'scrolled':''}`: `fas fa-bars ${windowWidth < 1040 && (scrollPosition>window.innerHeight * scrollThreshold|| navClick)?'scrolled':''}`} />
        </div>

        <ul className={click ? 'nav-menu active': 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={() => { closeMobileMenu(); scrollToTop(); setNavClick(false);}}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/About' className='nav-links' onClick={()=>{closeMobileMenu();checkNavClick();scrollToTop();}}>
              About
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/Records' className='nav-links' onClick={()=>{closeMobileMenu();checkNavClick();scrollToTop();}}>
              Records
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/Achievements' className='nav-links' onClick={()=>{closeMobileMenu();checkNavClick();scrollToTop();}}>
              Achievements
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/MyTeam' className='nav-links' onClick={()=>{closeMobileMenu();checkNavClick();scrollToTop();}}>
              My Team
            </Link>
          </li>
        </ul>
        
      </div>
      
    </nav>
    </>
  )
}

export default Navbar