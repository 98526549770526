import React from 'react'
import './Timeline.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Timeline() {
  return (
    <div className='timelineMainContainer'>
    <div className='timelineaccordion'>
    <input type="checkbox" id="check1" />
    <label class="accordion-label" for="check1">Click To Explore Yvonne's Life Journey &nbsp;<ion-icon name="footsteps-outline"></ion-icon></label>
    <div class="accordion-content">
        <ul className='timeline-listcontainer'>
        <li className='timeline-listitem' style={{'--accent-color':"#41516C"}}>
            <div className="date">1988</div>
            <div className="title">- Graduated from NUS Bachelor of Accountancy.</div>
            <div className='timeline__img'>
                <img src='images/timeline/nus.jpg' alt='record-img'/>
            </div>
            <div className="descr">- Started working as an auditor at KPMG providing services to MNCs.</div>
            <div className='timeline__img'>
                <img src='images/timeline/kpmg.jpg' alt='record-img'/>
            </div>
            <div className='end'></div>
        </li>
        <li className='timeline-listitem' style={{'--accent-color':"#E24A68"}}>
            <div className="date">1990</div>
            <div className="title">- Moved to Texas Instruments HQ Marketing Office as a financial analyst for 3 years.</div>
            <div className="descr">- Transferred to Texas Instruments Factory Operations as a senior accountant.</div>
            <div className="descr">- Responsible for overseeing the entirety of general accounting operations within the plant.</div>
            <div className='timeline__img'>
                <img src='images/timeline/texas.jpg' alt='record-img'/>
            </div>
            <div className='end'></div>
        </li>

        <li className='timeline-listitem' style={{'--accent-color':"#79A7D3"}}>
            <div className="date">1995</div>
            <div className="title">- Moved to Motorola to advance my career as a Finance Head.</div>
            <div className="descr">- Work here encompassed frequent delivery of financial presentations to senior management teams, both locally and in the United States.</div>
            <div className='timeline__img'>
                <img src='images/timeline/motorola.jpg' alt='record-img'/>
            </div>
            <div className='end'></div>
        </li>
        <li className='timeline-listitem' style={{'--accent-color':"#8A307F"}}>
            <div className="date">2001</div>
            <div className="title">- Took a career hiatus to support my husband's overseas assignment for two years in the United States, together with my 2 kids.</div>
            <div className='timeline__img'>
                <img src='images/timeline/usa.jpg' alt='record-img'/>
            </div>
            <div className='end'></div>
        </li>
        <li className='timeline-listitem' style={{'--accent-color':"#317773"}}>
            <div className="date">2013</div>
            <div className="title">- Worked in SparkleTots as a Flair Programme Teacher for 9 years, helping children to learn English proficiently. </div>
            <div className='timeline__img'>
                <img src='images/timeline/sparkle.jpg' alt='record-img'/>
            </div>
            <div className='timeline__img'>
                <img src='images/timeline/sparklekids.jpg' alt='record-img'/>
            </div>
            <div className='end'></div>
        </li>
        <li className='timeline-listitem' style={{'--accent-color':"#1B5F8C"}}>
            <div className="date">2016</div>
            <div className="title">- Dedicated eight years to serving on my condominium's MCST, with two of those years spent as Chairlady.</div> 
            <div className="descr">- As Chairlady, we celebrated Chinese New Year together with MP Low Yen Ling.</div>
            <div className='timeline__img'>
                <img src='images/timeline/cnydinner.jpg' alt='record-img'/>
            </div>
            <div className='timeline__img'>
                <img src='images/timeline/mplow.jpg' alt='record-img'/>
            </div>
            <div className="descr">- During this time, I also fulfilled roles as Secretary and Subcommittee Head for various projects.</div>
            <div className="descr">- This role has provided me with comprehensive knowledge of various issues related to housing. 
            Thus aiding in my work as a property agent, as it enables me to adeptly identify and address crucial considerations during property viewings for clients.</div>
            <div className='end'></div>
        </li>
        </ul>
        </div>
        {/* <div className='timelineaccordion'>
        <input type="radio" name="radio-a" id="check2" />
        <label class="accordion-label" for="check2">Accordion 1</label>
        </div> */}
        </div>
    </div>
  )
}

export default Timeline