import React from 'react'
import './Services.css'
function Services() {
  return (
    <div className='backgroundContainer'>
        
        <div className='servicesContainer'>
        <div className="infoGraphic">
            <div className='listItem'>
                <div className="numberWrap">
                    <div className="number fontColor1">1</div>
                    <div className="coverWrap">
                    <div className="numberCover"></div>
                    </div>
                </div>
                <div className="content">
                    <div className="icon iconSearch"></div>
                    <h2>Search</h2>
                    <p>Diligently source for the best properties catered to your needs in the shortest time possible.</p>
                </div>
            </div>
            <div className='listItem'>
                <div className="numberWrap">
                    <div className="number fontColor2">2</div>
                    <div className="coverWrap">
                    <div className="numberCover"></div>
                    </div>
                </div>
                <div className="content">
                    <div className="icon iconBarChart"></div>
                    <h2>Analyze</h2>
                    <p>Thoroughly analyze current market trends and conditions that may impact your property purchase or sale.</p>
                </div>
            </div>
            <div className='listItem'>
                <div className="numberWrap">
                    <div className="number fontColor3">3</div>
                    <div className="coverWrap">
                    <div className="numberCover"></div>
                    </div>
                </div>
                <div className="content">
                    <div className="icon iconMoney"></div>
                    <h2>Finances</h2>
                    <p>Expertly calculate your finances to ensure that any property purchases align seamlessly with your budget.</p>
                </div>
            </div>
            <div className='listItem'>
                <div className="numberWrap">
                    <div className="number fontColor4">4</div>
                    <div className="coverWrap">
                    <div className="numberCover"></div>
                    </div>
                </div>
                <div className="content">
                    <div className="icon iconHome"></div>
                    <h2>Assessment</h2>
                    <p>Provide a comprehensive and accurate assessment of your property's market value.</p>
                </div>
            </div>
            <div className='listItem'>
                <div className="numberWrap">
                    <div className="number fontColor5">5</div>
                    <div className="coverWrap">
                    <div className="numberCover"></div>
                    </div>
                </div>
                <div className="content">
                    <div className="icon iconCommunicate"></div>
                    <h2>Negotiate</h2>
                    <p>Actively communicate with homeowners or buyers to secure the most favorable prices on your purchase or sale.</p>
                </div>
            </div>
        </div>
        </div>
    
    </div>
  )
}

export default Services