import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';
function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

  return (
    <footer className="footer">
    {/* <div className="waves">
      <div className="wave" id="wave1"></div>
      <div className="wave" id="wave2"></div>
      <div className="wave" id="wave3"></div>
      <div className="wave" id="wave4"></div>
    </div> */}
    <ul className="social-icon">
    <li class="social-icon__item"><a class="social-icon__link" href="https://www.facebook.com/yvonne.see.923" target='_blank' aria-label="Facebook">
          <ion-icon name="logo-facebook"></ion-icon>
        </a></li>
        <li class="social-icon__item"><a class="social-icon__link"  href="https://wa.me/6596819137" target="_blank" rel="noopener noreferrer">
          <ion-icon name="logo-whatsapp"></ion-icon>
        </a></li>
    </ul>
    <ul className="menu">
      <li className="menu__item">
        <Link to='/' className='menu__link' onClick={scrollToTop}>
              Home 
        </Link>
        </li>
        <li className="menu__item">
        <Link to='/About' className='menu__link' onClick={scrollToTop}>
              About
        </Link>
        </li>      <li className="menu__item">
        <Link to='/Records' className='menu__link' onClick={scrollToTop}>
              Records
        </Link>
        </li>      <li className="menu__item">
        <Link to='/Achievements' className='menu__link' onClick={scrollToTop}>
              Achievements
        </Link>
        </li><li className="menu__item">
        <Link to='/MyTeam' className='menu__link' onClick={scrollToTop}>
              My Team
        </Link>
        </li>

    </ul>
    <p>&copy;2024 Yvonne See Properties | All Rights Reserved</p>
  </footer>
  )
}

export default Footer