import React from 'react'
import './PropertyTypes.css'
function PropertyTypes() {
  return (
    <div className='type__MainContainer'>
        <div className='type__Container'>
            <div className='first_row'>

                <div className='propType'>
                    <div className='propType__text'>
                        <h1>HDBs</h1>
                    </div>
                    <div className='propType__img'>
                        <img src='images/propTypes/propType-1.jpg' alt='record-img'/>
                    </div>    
                </div>
                <div className='propType'>
                    <div className='propType__text'>
                        <h1>Condominiums</h1>
                    </div>
                    <div className='propType__img'>
                        <img src='images/propTypes/propType-2.jpg' alt='record-img'/>
                    </div>    
                </div>
                <div className='propType'>
                    <div className='propType__text'>
                        <h1>Landed Properties</h1>
                    </div>
                    <div className='propType__img'>
                        <img src='images/propTypes/propType-3.jpg' alt='record-img'/>
                    </div>    
                </div>

            </div>
            <div className='second_row'>
                <h1>Experienced In Handling A Diverse Range Of Properties</h1>
            </div>
            <div className='third_row'>
                <div className='propType'>
                    <div className='propType__img'>
                        <img src='images/propTypes/propType-4.jpg' alt='record-img'/>
                    </div>
                    <div className='propType__text'>
                        <h1>Industrial Buildings</h1>
                    </div>    
                </div>
                <div className='propType'>
                    <div className='propType__img'>
                        <img src='images/propTypes/propType-5.jpg' alt='record-img'/>
                    </div>  
                    <div className='propType__text'>
                        <h1>Commercial Buildings</h1>
                    </div>  
                </div>
            </div>

        </div>
    </div>
  )
}

export default PropertyTypes