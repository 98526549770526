import React from 'react'
import '../App.css';
import './AboutMe.css'
import Services from './Services.js';
import PropertyTypes from './PropertyTypes.js';
import Timeline from './Timeline.js';

function AboutMe() {
  return (
    <>
    <div className='main--container'>
        <div className='img--container'>
            <img src='/images/img-5.jpg' alt='yvonne'/>
        </div>
        <div className='img--container-2'>
            <img src='/images/img-4.jpg' alt='yvonne'/>
        </div>
        <div className='text--container'>
          <p className='header'>About Yvonne See</p>
          <p className='quote'>Let's Find Your Dream Home.<br/> I'm Here To Help!</p>

          <p className='experience'>As a realtor with only 3 years of experience, I have earned numerous achievements over this 
          short period which are testament to my capability as a property agent.
          </p>

          
        </div>
    </div>
    <Timeline/>
    <Services/>
    <PropertyTypes/>
  </>
  )
}

export default AboutMe