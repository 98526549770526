import React from 'react'
import '../App.css';
import './HeroSection.css';


function HeroSection() {
  return (
    <div className='bigContainer'>
    <div className='background-container'>
    <div className='hero-container'>
        {/* <video src='/videos/video-2.mp4' autoPlay loop muted /> */}
        <h1>Looking For A New Home?</h1>
        <p>Your Preferred and Trusted Realtor</p>
        <a href="https://www.facebook.com/yvonne.see.923" class="social-button social-button--facebook" target='_blank' aria-label="Facebook">
        <i class="fab fa-facebook-f"><p></p></i>
        </a>

    </div>
    </div>
    </div>
  )
}

export default HeroSection