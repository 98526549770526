import React from 'react'
import './Team.css'

function Team() {
  return (
    <div className='team__MainContainer'>
    
     <div className='team__header'>
        <h1>
            <ion-icon name="trophy"></ion-icon>
            Member of An Award Winning Team
            <ion-icon name="trophy"></ion-icon>
        </h1>
     </div>
     <div className='team__body'>
        <div className='team__card'>
            <div className='team__img'>
                <img src='images/teams/team-1.jpg' alt='record-img'/>
            </div>
            <div className='team__text'>
                <h1>Matt Lam Advisory</h1>
            </div>    
        </div>
        <div className='team__card'>
            <div className='team__img'>
                <img src='images/teams/team-2.jpg' alt='record-img'/>
            </div>
            <div className='team__text'>
                <h1>Desmond Liew Division</h1>
            </div>    
        </div>
        {/* <div className='team__card'>
            <div className='team__img'>
                <img src='images/teams/team-3.jpg' alt='record-img'/>
            </div>
            <div className='team__text'>
                <h1>Nicholas Lim Associates</h1>
            </div>    
        </div> */}
     </div>
    
    </div>
  )
}

export default Team