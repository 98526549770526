import React from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import AboutMe from './components/AboutMe';
import Record from './components/Record.js';
import Cards from './components/Cards.js';
import Footer from './components/Footer.js';
import Team from './components/Team.js';
function App() {
  return (
   <>
   <Router>
      <Navbar />
      <a
        href="https://wa.me/6596819137"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
        <p class="whatsapp-text">Contact Me</p>
      </a>
      <Routes>
        <Route path='/' exact element={<Home/>}/>
        <Route path='/About' exact element={<AboutMe/>}/>
        <Route path='/Records' exact element={<Record/>}/>
        <Route path='/Achievements' exact element={<Cards/>}/>
        <Route path='/MyTeam' exact element={<Team/>}/>
        
      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
