import React from 'react';
import CardItem from './CardItem';
import './Cards.css';
import Testimonials from './Testimonials.js';

function Cards() {
  return (
    <div className='cards'>
        <p className='card__header'>My Achievements</p>

        <div className='cards__video'>
        <iframe className='propnex__video' src="https://www.youtube.com/embed/HSUlzxHS4Z8?si=vVYBLk07NRPspOiA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

        <div className="cards__container">

        <ul className="cards__items">
                    <CardItem src="images/awards/jul-2024.jpg"
                    text="Gold Achiever"
                    label='July 2024'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jun-2024.jpg"
                    text="Gold Achiever"
                    label='June 2024'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/apr-2024.jpg"
                    text="Gold Achiever"
                    label='April 2024'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/y2023-1.jpg"
                    text="Top 10% of Producers in 2023"
                    label='Year 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/y2023.jpg"
                    text="Top 74th Producer for the Year 2023"
                    label='Year 2023'
                    path='/services'
                    />
        </ul>
        <ul className="cards__items">
                    <CardItem src="images/awards/2Q-2023.jpg"
                    text="Top 140th Producer Among 12000 Propnex Agents"
                    label='2Q 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/oct-2023.jpg"
                    text="Gold Achiever"
                    label='October 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/sep-2023.jpg"
                    text="Gold Achiever"
                    label='September 2023'
                    path='/services'
                    />
        </ul> 

        <ul className="cards__items">
                    <CardItem src="images/awards/jul-2023.jpg"
                    text="Super Gold Achiever"
                    label='July 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jun-2023-3.jpg"
                    text={<div>Top 30 Producers<br/>(26th Place)</div>}
                    label='June 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jun-2023-2.jpg"
                    text={<div>Platinum Achiever<br/>(Award Winning Ceremony)</div>}
                    label='June 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jun-2023-1.jpg"
                    text="Platinum Achiever"
                    label='June 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/apr-2023.jpg"
                    text="Gold Achiever"
                    label='April 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jan-2023-3.jpg"
                    text={<div>Top 50 Producers<br/>(2nd Place)</div>}
                    label='January 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jan-2023-2.jpg"
                    text={<div>Platinum Producer<br/>(Award Winning Ceremony)</div>}
                    label='January 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jan-2023-1.jpg"
                    text="Platinum Producer"
                    label='January 2023'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/sep-2022-2.jpg"
                    text={<div>Top 100 Producers<br/>(95th Place)</div>}
                    label='September 2022'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/sep-2022-1.jpg"
                    text="Elite Gold Producer"
                    label='September 2022'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/may-2022.jpg"
                    text="Gold Champion"
                    label='May 2022'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/jan-2022.jpg"
                    text="Gold Champion"
                    label='January 2022'
                    path='/services'
                    />
        </ul>

        <ul className="cards__items">
                    <CardItem src="images/awards/nov-2021.jpg"
                    text="Gold Champion"
                    label='November 2021'
                    path='/services'
                    />
        </ul>
        </div>
        <Testimonials/>
    </div>
  )
}

export default Cards